<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="机构">
          <mechanismSelect :mecid.sync="mecid" @change="mecChange"></mechanismSelect>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="mobile" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="cour_type"
            placeholder="请选择"
            style="width: 100px"
            @change="handleChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询时间">
            <el-date-picker
      v-model="date"
      type="daterange"
      align="right"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd">
    </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
        <el-button @click.enter="toCheck" type="info" style="float: right"
          >时间审核</el-button
        >
      </el-form>
   
    </el-row>
      <!-- 列表 -->
      <el-table
        :data="DataList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="用户昵称" prop="nick_name"></el-table-column>
        <el-table-column label="用户电话" prop="mobile"></el-table-column>
        <el-table-column label="课程" prop="title"></el-table-column>
        <el-table-column label="结算金额" prop="settlement_cash"></el-table-column>
        <el-table-column label="上课时间" prop="start_time"></el-table-column>
        <el-table-column label="下课时间" prop="end_time"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <!-- <el-button type="warning" slot="reference" @click="adjustCourse"
            v-if=" row.checkin_status == 1" size="small"
              >课程调整</el-button> -->

            <el-popconfirm
              v-if="status == 2 && row.status == 2"
              title="确定核销该课程吗？"
              @confirm="toHexiao(row)"
            >
              <el-button type="primary" slot="reference" size="small">核销</el-button>
            </el-popconfirm>
            <el-button
                v-if="status == 10"
              type="info"
              @click="updateTime(row)"
              size="small"
              >修改时间</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="Total"
      ></paging-fy>


    <!-- 课程调整 -->
    <!-- <el-dialog title="核销调整" :visible.sync="adjustShow">
      <el-form>
        <el-form-item label="结算金额" required>
          <el-input
            v-model="adjust.price"
            placeholder="结算金额"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="调整原因" required>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="调整原因"
            v-model="adjust.resone"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="adjustSubmit" :disabled="adjusting" :key="adjusting"
            >提 交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog> -->
    <!-- 修改上课时间 -->
    <el-dialog title="修改时间" :visible.sync="timeshow" width="400px" >
    <el-form style="margin:0 auto">
        <el-form-item label="开始时间">
            <el-date-picker
      v-model="start_time"
      type="datetime"
      placeholder="选择日期时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      default-time="13:00:00">
    </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
            <el-date-picker
      v-model="end_time"
      type="datetime"
      placeholder="选择日期时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      :start-placeholder="start_time"
      default-time="14:00:00">
    </el-date-picker>
        </el-form-item>

        <el-form-item>
            <el-button type="primary" @click="subTime">确定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
  </div>
</template>
  
  <script>
import mechanismSelect from "../../components/select/mechanismSelect.vue";
export default {
  components: {
    mechanismSelect,
  },
  data() {
    return {
      nav: { firstNav: "客服管理中心", secondNav: "课程核销管理" },
      adjust: {},
      adjustShow: false,
      adjusting: false,
      adjust:{},
      DataList: [],
      is_loading: true,
      currentPage: 1,
      timeshow:false,
      curCourse:{},
      time:[],
      Total: 0,
      status: true,
      date:'',
      before_time:"",
      start_time:'',
      end_time:'',
      options: [
        {
          value: 1,
          label: "待上课",
        },
        {
          value: 2,
          label: "已完成",
        },
        {
          value: 3,
          label: "已过期",
        },
      ],
      mobile: "",
      mecid: "",
      price: "",
      status: 2,
      cour_type: 1,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    mecChange(v){
      this.mecid = v
    },
    toCheck() {
      this.$router.push({ name: "TimeManage" });
    },
    handleChange(v) {
      switch (v) {
        case 1:
         this.status = 2
          break;
        case 2:
         this.status = 3
          break;
        case 3:
         this.status = 10
          break;
      }
    },

    updateTime(row) {
      this.timeshow = true;
      this.curCourse = row;
    },
    //时间选择
    timeCheck(){
        let now = new Date(this.start_time).getTime();
        let temp = new Date(this.end_time).getTime();
        if (now > temp) {
        return false;
      } else {
        return true;
      }
    },
    isdeadline(time) {
      let now = new Date().getTime();
      let temp = new Date(time).getTime();
      if (now > temp) {
        return false;
      } else {
        return true;
      }
    },
    //修改时间
    subTime() {
        if(!this.timeCheck()){
            this.$message({ message: "结束时间不能小于开始时间" });
             return;
        }
      if (!this.end_time || !this.start_time) {
        this.$message({ message: "请选择时间" });
        return;
      }
      if (this.isdeadline(this.end_time) && this.isdeadline(this.start_time)) {
        let url = "user/masterNotice/insert";
        let data = {
          appointment_id: this.curCourse.id,
          type: "换课",
          change_starttime: this.time[0],
          change_endtime: this.time[1],
        };
        this.$axios({ url, data, method: "post" }).then((res) => {
          this.$message({ message: res.data.message });
          this.timeshow = false;
        });
      } else {
        this.$message({ message: "不能选择过去的时间" });
      }
    },
        //核销
        toHexiao(row) {
      let url = "user/userAppointment/checkAttendClass";
      let data = {
        id: row.id,
        mechanism_id: row.mechanism_id,
      };
      this.$axios({ url, data, method: "post" }).then((res) => {
        this.$message({ message: res.data.message });
        this.GetAppointDetail();
      });
    },
    //课程
    getList() {
      this.is_loading = true;
      let data = {};
      let url = "/user/userAppointment/list/page";
      data = {
        currentPage: 1,
        pageSize: 10,
        offset: 8,
        status: this.status,
        master_type: "mechanism_offline",
      };
      if(this.statuas == 2){
        data.attend_class_start_time= new Date().Format('yyyy-MM-dd') + ' 00:00:00'
      data.attend_class_end_time= new Date().Format('yyyy-MM-dd') + ' 24:00:00'
      }
      if(this.mobile ){
        data.mobile= this.mobile
      }
      if(this.mecid ){
        data.mechanism_id= this.mecid
      }
      if(this.date){
        data.attend_class_start_time= this.date[0] + ' 00:00:00' 
        data.attend_class_end_time= this.date[1] + ' 24:00:00'
      }
      this.$axios({ url, params: data, method: "get" }).then((res) => {
        this.is_loading = false;
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total
      });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>
  
  <style lang="less">
</style>
  